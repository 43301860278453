.div1 {
  position: relative;
}

.div2 {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  touch-action: none;
}
.streamButton {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px 20px; /* Adjust the padding to make the button bigger */
  font-size: 24px; /* Adjust the font size to make it larger */
  background-color: #3498db; /* Button background color */
  color: #fff; /* Button text color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 2; /* Ensure the button appears above other elements */
}

/* Hover effect for the button */
.streamButton:hover {
  background-color: #2980b9; /* Button background color on hover */
}
.video-container {
  background-color: black;
  height: 100%;
  margin: 0;
  padding: 0;
}
.logo {
  /* position: absolute; */
  position: fixed;
  margin-top: 55vh;
  left: 50%;
  margin-bottom: 5vh;
  max-width: 50vw;
  transform: translateX(-50%);
  touch-action: none;
  opacity: 0.3;
  align-items: bottom;
}
.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  flex: 1;
  height: 100vh;

  overflow: hidden;
  margin: 0;
  padding: 0;
}

.div3 {
  position: absolute;
  width: 100px;
  height: 100px;
  background-color: rgba(1, 1, 1, 0);
  touch-action: none;
  cursor: move;
  align-items: middle;
  z-index: 1;
  overflow: contain;
  user-select: none;
  touch-action: none;
}

.div3 img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.splash-screen img {
  width: 50%;
  height: auto;
  max-width: 500px;
}
